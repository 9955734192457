import React from 'react';
import IllnessDataBaseContainer from "./IllnessDataBaseContainer";

class IllnessDataBaseScreen extends React.Component {

    public render() {
        return <IllnessDataBaseContainer />
    }

}

export default IllnessDataBaseScreen