import React, {FormEvent} from 'react';
import IllnessDataBaseView from "./IllnessDataBaseView";
import TaskGetIllnessDataBase from "../../ws/TaskGetIllnessDataBase";
import {Illness, IllnessInfo} from "../../models/IllnessDataBase";
import TaskDeleteIllness from "../../ws/TaskDeleteIllness";
import TaskCreateIllness from "../../ws/TaskCreateIllness";
import {Link} from "react-router-dom";
import ModalCreateSubgroup from "./ModalCreateSubgroup";
import {LoadingView} from "@cuatroochenta/co-admin-react-library";

export interface IllnessDatabaseResponse {
    success: boolean,
    error: string,
    message: string
}

class IllnessDatabaseContainer extends React.Component {

    public state = {
        illness: null as [IllnessInfo] | null,
        createIllnessSuperior: null as Illness | null,
        message: null as string | null,
        loading: true,
    };

    public componentWillMount() {
        this.getIllness();
    }

    private getIllness() {
        this.setState({loading: true});
        new TaskGetIllnessDataBase().get()
            .then(response => {
                    this.setState({illness: response.data, loading: false});
                }
            )
    }

    private deleteIllness(illnessId: bigint) {
        this.setState({loading: true});
        new TaskDeleteIllness().get(illnessId.toString())
            .then(() => this.onResponse())
    }

    private onResponse() {
        this.getIllness();
    }

    private showCreateIllnessModal(illnessId: Illness) {
        this.setState({"createIllnessSuperior": illnessId});
    }

    public render() {
        return (
            <div>
                <LoadingView loading={this.state.loading} />
                <Link className='btn create-btn' to={`/illness/create`}>Añadir</Link>
                <IllnessDataBaseView
                    illness={this.state.illness}
                    deleteIllness={this.deleteIllness.bind(this)}
                    createSubgroup={this.showCreateIllnessModal.bind(this)}
                    message={this.state.message}
                />
                <ModalCreateSubgroup createIllnessSuperior={this.state.createIllnessSuperior} createIllness={this.createIllness.bind(this)}/>
            </div>
        );
    }

    private createIllness(event: FormEvent, nameFromForm: string) {
        event.preventDefault();
        event.stopPropagation();

        const name = nameFromForm.trim();

        if (name != "" && this.state.createIllnessSuperior != null) {
            this.setState({loading: true});
            new TaskCreateIllness().get(name, this.state.createIllnessSuperior.oid)
                .then(r => {
                    this.setState({"name": ""});
                    this.setState({"createIllnessSuperior": null});
                    this.setMessage(`Se ha creado el subnivel ${name}`);
                    this.onResponse();
                });
        }

        this.setState({"createIllnessSuperior": null});
    }

    private setMessage(message:string) {
        this.setState({message: message});
        setTimeout(() => this.setState({message: null}), 10000);
    }
}

export default IllnessDatabaseContainer;