import Urls from "./Urls";
import Utils from "./Utils";

export default class TaskGetIllnessDataBase {

    private getPostInfo() {
        return {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + Utils.getToken()
            },
            body: JSON.stringify({})
        }
    }

    public get() {
        return fetch(Urls.URL_GET_ILLNESS_DATABASE, this.getPostInfo())
            .then(response => response.json())
            .then(data => {
                return data
            });
    }
}