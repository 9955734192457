import React from 'react';
import { FormRenderData, Module } from '@cuatroochenta/co-admin-react-library';
import CustomAction from './CustomAction';

import '@cuatroochenta/co-admin-react-library/lib/index.css';
import IllnessDataBaseScreen from "./actions/modules/IllnessDataBase/IllnessDataBaseScreen";

interface Filters {
    [dynamic: string]: string
}

class App extends React.Component {

    private beforeFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private afterFormView = (entity: string, type: string, data: FormRenderData): React.ReactNode => {
        return null;
    };

    private customButtonsList = (entity: string, filters: Filters, orderby: string, order: string): React.ReactNode => {
        return null;
    };

    private afterLoginButton = (): React.ReactNode => {
        return null;
    };

    public render() {
        return (
            <Module
                actions={[
                    { name: 'illnessdatabase', element: <IllnessDataBaseScreen/> }
                ]}
                beforeFormView={this.beforeFormView}
                afterFormView={this.afterFormView}
                customButtonsList={this.customButtonsList}
                afterLoginButton={this.afterLoginButton}
            />
        );
    }

}

export default App;
