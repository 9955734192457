import {Illness, IllnessInfo} from "../../models/IllnessDataBase";
import React, {FormEvent} from "react";
import {Button, Form, Modal} from "react-bootstrap";

interface ModalCreateSubgroupProps {
    createIllnessSuperior: Illness | null,
    createIllness: (event: FormEvent, name: string) => void
}

class ModalCreateSubgroup extends React.Component<ModalCreateSubgroupProps> {

    public state = {
        name: ""
    }

    public render() {
        if (this.props.createIllnessSuperior == null) {
            return <div/>
        }

        return (
            <Modal show={true} onHide={() => this.setState({"createIllnessSuperior": null})}>
                <Modal.Header>
                    <Modal.Title>Creando subnivel para {this.props.createIllnessSuperior.name}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={(e:FormEvent) => this.props.createIllness(e, this.state.name)}>
                        <Form.Group className="mb-3" controlId="name">
                            <Form.Control onChange={e => this.setState({"name": e.target.value})} type="text"
                                          placeholder="Nombre"/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            Guardar
                        </Button>
                    </Form>
                </Modal.Body>
            </Modal>
        );
    }
}

export default ModalCreateSubgroup