import Urls from "./Urls";
import Utils from "./Utils";

export default class TaskDeleteIllness {

    private getDeleteInfo() {
        return {
            method: 'DELETE',
            headers: {
                'Authorization': 'Bearer ' + Utils.getToken()
            }
        }
    }

    public get(id: string) {
        return fetch(Urls.URL_ILLNESS_DELETE.replace(':oid', id), this.getDeleteInfo())
            .then(response => response.json())
            .then(data => {
                return data
            });
    }
}