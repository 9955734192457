import Urls from "./Urls";
import Utils from "./Utils";

export default class TaskCreateIllness {

    private getPostInfo(name:string, superiorId:bigint) {
        return {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + Utils.getToken()
            },
            body: JSON.stringify({
                "entity": {
                    "name":name,
                    "superiorIllness": superiorId
                },
            })
        }
    }

    public get(name:string, superiorId:bigint) {
        return fetch(Urls.URL_ILLNESS_CREATE, this.getPostInfo(name, superiorId))
            .then(response => response.json())
            .then(data => {
                return data
            });
    }
}